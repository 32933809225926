<template>
  <div class="body">
    <div class="content">
      <div class="content-video">
        <div class="content-video-top">
          <div>
            <div class="content-video-top-text">{{ title }}</div>
            <div class="line"></div>
          </div>
        </div>
        <div v-if="videos.length > 0">
          <div v-if="type == 'video'" class="content-video-bottom">
            <div
              class="content-video-item"
              v-for="(item, index) in videos"
              :key="index"
              @click="viewVideo(item)"
            >
              <img
                v-if="item.coverUrl != '' && item.coverUrl != null"
                :src="item.coverUrl"
                class="content-video-item-top"
              />
              <img
                v-else
                src="../../assets/empty.png"
                class="content-video-item-top"
              />
              <div class="content-video-item-bottom">
                <div class="content-bottom-title">{{ item.name }}</div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="videos.length > 0">
              <div class="content-video-bottom">
                <div
                  class="content-video-item"
                  v-for="(item, index) in videos"
                  :key="index"
                  @click="viewLive(item)"
                >
                <div class="live-label" >
                  <img
                    v-if="item.cover != '' && item.cover != null"
                    :src="item.cover"
                    class="content-video-item-top"
                  />
                  <img
                    v-else
                    src="../../assets/empty.png"
                    class="content-video-item-top"
                  />
                  <span v-if="item.start<item.newDate" style="background-color: #ff5e90;">直播中</span>
                  <span v-else style="background-color: rgba(0,0,0,0.5)">未开播</span>
                </div>
                  <div class="content-video-item-bottom">
                    <div class="content-bottom-title">{{ item.name }}</div>
                    <div class="gray-14">{{item.courseName}}</div>
                    <div class="gray-14">直播时间：{{ item.startTime ? item.startTime.substring(5,16) : '' }}</div>
                    
                    <div class="gray-14" style="display: flex;"><img :src="item.coverUrl" alt="" class="live-imgTitle"><div>{{item.lecturerName}}</div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <el-empty description="暂无数据"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLiveList,
  hotKnowledgePoints,
  getLiveInfo,
  getHotInfo,
  getLivePlay,
} from "@/api/course";
export default {
  data() {
    return {
      type: "",
      title: "",
      selectCourseId: 0,
      videos: [],
      random: "",
      liveItem: {},
      selectCourseId: 0,
      courseId: 0,
    };
  },
  mounted() {
    this.selectCourseId = this.$route.query.id;
    this.type = this.$route.query.type;
    if (this.type === "video") {
      this.title = "全部视频";
      this.hotKnowledgePoints();
    } else {
      this.title = "全部直播";
      this.getLiveList();
    }
  },
  methods: {
    viewVideo(item) {
      getHotInfo({ id: item.id }).then((res) => {
        if (res.code == 200) {
          this.$router.push({ path: "viewVideo", query: res.data });
        }
      });
    },
    viewLive(item) {
       //直播中
      if(item.start<item.newDate){
        getLiveInfo(item.id).then((res) => {
          if (res.code == 200) {
            this.liveItem = res.data;
            // used为0时才需要输入随机码
            if (this.liveItem.used === 0) {
              this.inputRandom();
            } else {
              this.openLive();
            }
          }
        });
      }else{
        this.$message({
          message: '还未开播哦~',
          type: 'warning'
        });
      }
    },
    openLive() {
      // 输入随机码先检测随机码的正确性
      getLivePlay({ random: this.random, id: this.liveItem.id }).then((res) => {
        if (res.code == 200) {
          this.$router.push({
            path: "/viewLive",
            query: { random: this.random, data: this.liveItem },
          });
        }
      });
    },
    inputRandom() {
      this.$prompt("请输入随机码", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showClose: false,
      }).then(({ value }) => {
        if (value != null && value != "") {
          this.random = value;
          this.openLive();
        } else {
          this.$message.error("随机码不能为空");
        }
      });
    },
    getLiveList() {
      getLiveList({ courseId: this.selectCourseId, free: 1 }).then((res) => {
        if (res.code == 200) {
          this.videos = res.data.list;

          this.videos.forEach((item, index) => {
            var startTime =Number(new Date(item.startTime))//直播开始时间
            var newDate = Number(new Date());     //当前时间
            this.videos[index]['start']=startTime 
            this.videos[index]['newDate']=newDate  
            
          })
        }
      });
    },
    hotKnowledgePoints() {
      hotKnowledgePoints({
        courseId: this.selectCourseId,
      }).then((res) => {
        if (res.code == 200) {
          this.videos = res.data.list;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 1200px;
  height: 100vh;
  margin: 0 auto;
  .content-video {
    width: 100%;
    height: 288px;
    .content-video-top {
      display: flex;
      justify-content: space-between;
      .content-video-top-text {
        width: 80px;
        font-weight: 400;
        font-size: 20px;
      }
      .content-video-top-button {
        width: 70px;
        height: 26px;
        border-radius: 23px;
        border: 1px solid #4394ff;
        color: #4394ff;
        font-size: 10px;
        background-color: white;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    .content-video-bottom {
      display: flex;
      flex-wrap: wrap;
      margin-top: 25px;
      .content-video-item {
        width: 260px;
        margin-right: 14px;
        cursor: pointer;
        margin-bottom: 30px;
        .content-video-item-top {
          width: 100%;
          height: 180px;
        }
        .content-video-item-bottom {
          width: 247px;
          height: auto;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          text-align: left;
          padding-left: 10px;
          .content-bottom-title {
            padding-top: 15px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
          }
          .content-bottom-subtitle {
            padding-top: 10px;
            font-size: 14px;
            color: #646464;
          }
          .content-bottom-content {
            display: -webkit-inline-box;
            line-height: 15px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
.line {
  height: 5px;
  width: 100%;
  margin-top: 4px;
  background-color: #4394ff;
  border-radius: 10px;
}
.live-label{
  position: relative;
  span{
    width: 51px;
    height: 18px;
    position: absolute;
    top: 4px;
    right: 4px;
    display: inline-block;
    
    font-size: 12px;
    color: white;
    border-radius: 2px;
    box-sizing: border-box;
    line-height: 18px;
    text-align: center;
    
  }
}
.content-video-bottom {
  display: flex;
  .content-video-item {
    width: 260px;
    margin-right: 58px;
    border: 2px solid #E8E8E8;
    border-radius: 5px;
    cursor: pointer;
    .content-video-item-top {
      width: 100%;
      height: 180px;
      border-radius: 5px;
    }
    .content-video-item-bottom {
      width: 247px;
      
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      text-align: left;
      padding-left: 10px;
      .content-bottom-title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
      }
      .content-bottom-subtitle {
        padding-top: 10px;
        font-size: 14px;
        color: #646464;
      }
      .content-bottom-content {
        display: -webkit-inline-box;
        line-height: 15px;
        font-size: 12px;
      }
      .gray-14{
        font-size: 14px;
        font-weight: 400;
        color: #646464;
      }
      div{
        margin: 10px 0;
      }
    }
  }
}
.live-imgTitle{
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>